/** @jsxImportSource @emotion/react */
import { StaticImage } from "gatsby-plugin-image";
import CareerDetail from "../../features/career_detail";
import { ress } from "../../components/shared/mtext";

export default (props: Object) => {
  return (
    <CareerDetail
      {...props}
      locales={{
        it: {
          title: "Backend developer",
          seoDescription:
            "Essere lofter significa curiosità, ambizione e passione. Lavoriamo per portare innovazione nel mondo del software e dei prodotti digitali. Ti va di unirti a noi?",
          roles: [
            "Svilupperai e manterrai le infrastrutture backend con un focus su Node.js e Express.",
            "Contribuirai alla progettazione e ottimizzazione dell'architettura backend.",
            "Sarai responsabile della gestione dei database, principalmente MongoDB e PostgreSQL, assicurando prestazioni e sicurezza.",
            "Collaborerai con il team per integrare nuove tecnologie e soluzioni innovative.",
            "Parteciperai attivamente alla risoluzione di problemi tecnici e alla ricerca di miglioramenti.",
          ],
          prerequisites: [
            "Conoscenza di Node.js, con familiarità in JavaScript e TypeScript.",
            "Esperienza o capacità di lavorare con MongoDB o PostgreSQL.",
            "Abilità nella risoluzione di problemi e interesse per l'innovazione tecnologica.",
            "Capacità di lavorare in modo collaborativo e indipendente.",
            "Gradita una comprensione di base delle architetture software e dei design patterns.",
          ],
          requiredTools: [
            {
              name: "Node.js",
              image: (
                <StaticImage
                  alt=""
                  src={"../../features/career_detail/images/backendNodeJs.png"}
                  css={{
                    margin: "4px",
                    width: "70px",
                  }}
                />
              ),
            },
            {
              name: "Express",
              image: (
                <StaticImage
                  alt=""
                  src={"../../features/career_detail/images/backendExpress.png"}
                  css={{ width: "70px", margin: "4px" }}
                />
              ),
            },
            {
              name: "Typescript",
              image: (
                <StaticImage
                  alt=""
                  src={"../../features/career_detail/images/backendTs.png"}
                  css={{ width: "70px", margin: "4px" }}
                />
              ),
            },
            {
              name: "MongoDB",
              image: (
                <StaticImage
                  alt=""
                  src={"../../features/career_detail/images/backendMongoDb.png"}
                  css={{ width: "70px", margin: "4px" }}
                />
              ),
            },
            {
              name: "PostgreSQL",
              image: (
                <StaticImage
                  alt=""
                  src={"../../features/career_detail/images/backendPostGre.png"}
                  css={{ width: "70px", margin: "4px" }}
                />
              ),
            },
          ],
          yearsOfExperience: 0,
        },
      }}
    />
  );
};
